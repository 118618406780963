<template>
  <div class="password">
    <a-form-model ref="form" :rules="form.rules" :model="form.data" @submit="onSubmit" @submit.native.prevent>
    <a-card title="修改密码">
        <a-form-model-item prop="current" label="当前密码">
          <a-input type="password" v-model="form.data.current" placeholder="请输入当前密码" />
        </a-form-model-item>
        <a-form-model-item prop="password" label="新密码">
          <a-input type="password" v-model="form.data.password" placeholder="请输入新密码" />
        </a-form-model-item>
        <a-form-model-item prop="confirm" label="确认密码">
          <a-input type="password" v-model="form.data.confirm" placeholder="请再次输入新密码" />
        </a-form-model-item>
      <template slot="actions">
        <a-button type="primary" html-type="submit">提交修改</a-button>
        <a-button class="reset" @click="onReset">重置</a-button>
      </template>
    </a-card>
    </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'Password',
    data () {
      let validator = {
        confirm: (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入新密码'));
          } else if (value !== this.form.data.password) {
            callback(new Error("两次密码输入不一致"));
          } else {
            callback();
          }
        }
      };
      return {
        form: {
          layout: {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          },
          rules: {
            current: [{ type: 'string', required: true, message: '当前密码不可为空' }],
            password: [{ type: 'string', required: true, message: '新密码不可为空' }],
            confirm: [{ validator: validator.confirm }],
          },
          data: {
            current: '', password: '', confirm: ''
          }
        }
      }
    },
    methods: {
      onReset() {
        this.$refs.form.resetFields();
      },
      onSubmit() {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.password();
          }
        });

      },
      password (){
        this.$post('/password', this.form.data).then( res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .password {
    margin: 80px auto; width: 480px;
  }
  .password h3 {
    /*text-align: center;*/
  }
  .password .box-body {
    padding: 30px;
  }
</style>
